<template>
  <div class="image-editor">
    <div class="description-wrap py-4 px-4 d-flex" style="border-bottom: 1px solid #444">
      <v-btn class="mr-2 mt-2" small icon v-if="prevRoute && prevRoute.name" :to="{ name: prevRoute.name }"
        ><v-icon class="mr-1">mdi-chevron-left</v-icon>
      </v-btn>

      <v-textarea
        style="margin-left: -6px"
        hide-details
        dense
        filled
        outlined
        v-model="issue.description"
        auto-grow
        rows="1"
        row-height="15"
        class="textarea"
        label="Log-it description"
        @blur="updateIssue"
      >
        <template v-slot:prepend v-if="$vuetify.breakpoint.mdAndUp">
          <v-avatar width="40" height="28" tile style="position: relative; max-height: 28px">
            <img :src="require('@/assets/log-it-logo-opacity.png')" />
          </v-avatar>
        </template>
      </v-textarea>
      <v-btn class="ml-2" style="margin-top: 2px" color="primary" @click="submitIssue">{{ $t("t_submit") }}</v-btn>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="margin: 6px 0 0 0px; position: relative; right: -8px"
            icon
            small
            @click="asset = null"
            color="secondary"
            v-bind="attrs"
            v-on="on"
            ><v-icon>mdi-dots-vertical</v-icon></v-btn
          >
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-btn text small @click="deleteIssueConfirm">
                <v-icon small class="mr-2">mdi-delete</v-icon> {{ $t("t_delete") }}
              </v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="mx-4 py-2 pb-3">
      <v-row no-gutters>
        <v-col cols="12" md="5">
          <div>
            <v-btn
              :disabled="!issueId"
              @click="mode = 'comment'"
              class="btn-editor mr-2 mt-1"
              :class="{ active: mode == 'comment' }"
            >
              <v-icon class="mr-2">mdi-comment-text</v-icon>
              {{ $t("t_comment") }}
            </v-btn>

            <v-btn
              :disabled="!issueId"
              @click="mode = 'draw'"
              class="btn-editor mt-1"
              :class="{ active: mode == 'draw' }"
            >
              <v-icon class="mr-2">mdi-draw</v-icon>
              {{ $t("t_draw") }}
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="mode == 'draw'" cols="12" md="7" :class="$vuetify.breakpoint.mdAndUp ? 'text-right' : ''">
          <div :class="$vuetify.breakpoint.smAndDown ? 'mt-1' : 'mt-0'">
            <v-btn
              class="mt-1 btn-tool"
              @click="tool = 'pen'"
              :x-small="$vuetify.breakpoint.xsOnly"
              :class="{ active: tool == 'pen' }"
            >
              <v-icon class="mr-1" small>mdi-pen</v-icon> {{ $t("t_pen") }}
            </v-btn>

            <v-btn
              class="ml-2 mt-1 btn-tool"
              @click="tool = 'marker'"
              :x-small="$vuetify.breakpoint.xsOnly"
              :class="{ active: tool == 'marker' }"
            >
              <v-icon class="mr-1" small>mdi-marker</v-icon> {{ $t("t_highlight") }}
            </v-btn>

            <v-btn
              class="btn-tool mt-1 ml-2"
              @click="tool = 'eraser'"
              :x-small="$vuetify.breakpoint.xsOnly"
              :class="{ active: tool == 'eraser' }"
            >
              <v-icon class="mr-1" small>mdi-eraser</v-icon> {{ $t("t_erase") }}
            </v-btn>

            <v-tooltip bottom z-index="100">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 mt-1 btn-tool"
                  @click="tool = 'textbox'"
                  icon
                  :x-small="$vuetify.breakpoint.xsOnly"
                  :class="{ active: tool == 'textbox' }"
                >
                  <v-icon>mdi-format-text-variant-outline </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("t_textbox") }}</span>
            </v-tooltip>

            <v-menu
              style="z-index: 101"
              ref="menu-color-picker"
              v-model="showColorPicker"
              :close-on-content-click="false"
              transition="slide-x-reverse-transition"
              min-width="auto"
              :nudge-left="256"
              :nudge-bottom="44"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="btn-tool mt-1 ml-2"
                  icon
                  :x-small="$vuetify.breakpoint.xsOnly"
                  :class="{ active: tool == 'color-select' }"
                >
                  <v-icon :color="pickerColor">mdi-circle-slice-8</v-icon>
                </v-btn>
              </template>
              <v-color-picker
                v-if="showColorPicker"
                class="color-picker"
                dot-size="25"
                swatches-max-height="200"
                mode="hexa"
                v-model="pickerColor"
              ></v-color-picker>
            </v-menu>
            <v-btn
              class="btn-submit ml-2 mt-1"
              color="primary"
              @click="saveMarkup"
              :small="$vuetify.breakpoint.xsOnly"
              >{{ $t("t_save") }}</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </div>

    <div
      class="canvas-wrap"
      id="canvasWrap"
      ref="canvasWrap"
      :class="mode + ' ' + tool"
      :width="canvasWidth"
      :height="canvasHeight"
    >
      <canvas class="canvas fabric" ref="canvasFabric"></canvas>
      <img id="photo" class="photo" :src="imageSrc" :width="canvasWidth" />
      <div
        @click.self="addComment"
        ref="comments"
        class="comments-wrap"
        :style="{
          width: canvasWidth + 'px',
          height: canvasHeight + 'px',
          zIndex: mode == 'comment' ? 30 : 1,
        }"
      >
        <div v-for="(comment, index) in comments" :key="index">
          <div
            class="comment-wrap"
            :class="currentComment?.index == index ? 'active' : ''"
            :style="{
              top: comment.yCoord + '%',
              left: comment.xCoord + '%',
              zIndex: comment.showDetails ? 34 : 1,
            }"
          >
            <div class="comment" :id="'comment-' + index">
              <div
                class="trigger-show-comment"
                :id="'triggerToggleComment_' + index"
                :class="{ 'show-move': comment.showDetails }"
                @mousedown="triggerComment($event, comment)"
                @touchstart="triggerComment($event, comment)"
                @mouseup="toggleComment(index)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                  <circle
                    class="large-circle"
                    cx="25"
                    cy="25"
                    r="18"
                    :fill="isNotOwner(comment) ? '#f9eaad' : '#fbecec'"
                  />
                  <circle cx="25" cy="25" r="12" :fill="commentColor(comment.severity)" />
                  <text x="25" y="30" font-family="Arial" font-size="16" text-anchor="middle" fill="white">
                    {{ index + 1 }}
                  </text>
                </svg>

                <div class="edit-connect" v-if="comment.showDetails"></div>
              </div>

              <div
                :style="{
                  left: `calc(${-comment.xCoord}% + 25px)`,
                  top: '15px',
                  width: canvasWidth - 30 + 'px',
                }"
                :id="'editComment_' + index"
                class="edit-comment"
                :class="{ dark: $vuetify.theme.dark }"
                v-if="comment.showDetails"
              >
                <v-btn class="btn-close-comment" @click="hideComment(index)" icon x-small
                  ><v-icon color="grey darken-2" small>mdi-close-thick</v-icon></v-btn
                >
                <div class="d-flex align-center">
                  <span class="mr-2 mt-0 pt-0 pl-1 overline" style="font-size: 14px !important"
                    >Log-It {{ $t("t_comment") }}</span
                  >
                  <v-menu offset-y v-model="info1">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon color="grey">mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title class="pb-0">Log-it {{ $t("t_comment") }} {{ $t("t_severity") }}</v-card-title>
                      <v-card-text class="pb-0">
                        <strong>{{ $t("t_store") }}-it</strong>: {{ $t("t_store_info") }}<br />
                        <strong>{{ $t("t_review") }}-it</strong>: {{ $t("t_review_info") }}<br />
                        <strong>{{ $t("t_escalate") }}-it</strong>: {{ $t("t_escalate_info") }}
                      </v-card-text>
                      <v-card-actions>
                        <v-btn text color="primary" @click="info1 = false">{{ $t("t_close") }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-spacer></v-spacer>
                  <v-icon style="position: relative; right: 0; top: -4px" large :color="commentColor(comment.severity)">
                    mdi-fire
                  </v-icon>
                </div>

                <div class="comment-read pa-2" v-if="isNotOwner(comment)">
                  {{ comment.comment }}
                </div>
                <v-textarea
                  v-else
                  dense
                  filled
                  auto-grow
                  rows="1"
                  row-height="15"
                  hide-details="true"
                  class="textarea"
                  v-model="comment.comment"
                ></v-textarea>

                <div class="text-right mt-4" v-if="isNotOwner(comment)">
                  <app-user-avatar
                    class="comment-avatar"
                    :user="{
                      profileImageAssetId: comment.owner?.profileImageAssetId,
                      profileImageAssetKey: comment.owner?.profileImageAssetKey,
                      initials: comment.owner?.initials,
                      userId: comment.owner?.id,
                    }"
                    :size="28"
                  />
                  <span class="mt-2 ml-2">{{ comment.ownerFriendlyName }}</span>
                </div>
                <v-slider
                  class="mt-2"
                  v-model="comment.severity"
                  :tick-labels="ticksLabels"
                  :color="commentColor(comment.severity)"
                  min="1"
                  max="3"
                  step="1"
                  ticks="always"
                  tick-size="4"
                  :disabled="isNotOwner(comment)"
                >
                </v-slider>
                <span class="d-flex" v-if="!isNotOwner(comment)">
                  <v-btn color="primary" small class="mt-2" @click="submitComment(comment, true)">{{
                    $t("t_submit")
                  }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click="comment.id ? removeCommentConfirm(index, $event) : removeComment(index, $event)"
                    x-small
                    class="mt-2"
                    >{{ $t("t_delete") }}</v-btn
                  >
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import { AssetService, IssueService } from "@/services";
import { mapGetters, mapActions } from "vuex";
import AppConfirmDialog from "@/components/AppConfirmDialog.vue";
import AppUserAvatar from "@/components/AppUserAvatar.vue";
import { fabric } from "fabric-with-erasing";
import helpers from "@/mixins/helpers";

export default {
  name: "IssueEditor",
  mixins: [helpers],
  data() {
    return {
      isDrawing: false,
      isDragging: false,
      canvasWidth: 800,
      canvasHeight: 600,
      canvasFabric: null,
      mode: "comment",
      tool: "pen",
      comments: [],
      offset: { xCoord: 0, yCoord: 0 },
      currentComment: null,
      pickerColor: "#FF0000",
      showColorPicker: false,
      issue: { description: "" },
      issueId: null,
      imageSrc: null,
      originalWidth: null,
      originalHeight: null,
      drawSavePending: false,
      info1: false,
    };
  },
  components: {
    AppConfirmDialog,
    AppUserAvatar,
  },
  props: {
    imagePhotoProp: {
      type: Object,
      default: null,
    },
    issueProp: {
      type: Object,
      default: null,
    },
    prevRoute: {
      type: Object,
      default: null,
    },
  },
  watch: {
    mode(mode) {
      this.canvasFabric.discardActiveObject();
      this.canvasFabric.requestRenderAll();
      this.$refs.comments.style.zIndex = 1;
      this.$refs.canvasFabric.parentElement.style.zIndex = 1;
      this.canvasFabric.isDrawingMode = false;
      if (mode === "comment") {
        this.$refs.comments.style.zIndex = 30;
      } else if (mode === "draw") {
        this.$refs.canvasFabric.parentElement.style.zIndex = 30;
        this.canvasFabric.isDrawingMode = true;
      }
    },

    pickerColor() {
      const pencilBrush = new fabric.PencilBrush(this.canvasFabric);
      pencilBrush.color = this.pickerColor;
      this.setTool();
    },

    tool() {
      this.setTool();
    },
  },
  computed: {
    ...mapGetters("user", ["user"]),
    penWidth() {
      return Math.round(this.canvasWidth * 0.005 + 5);
    },
    ticksLabels() {
      return [this.$t("t_store") + "-it", this.$t("t_review") + "-it", this.$t("t_escalate") + "-it"];
    },
  },
  methods: {
    ...mapActions("loader", ["pending", "done"]),
    ...mapActions("issue", ["setImage"]),

    setTool() {
      this.canvasFabric.discardActiveObject();
      this.canvasFabric.requestRenderAll();
      //this.disableEraserMode();
      this.allowTextSelectionOnly();
      this.canvasFabric.off("mouse:down");
      switch (this.tool) {
        case "textbox": {
          this.canvasFabric.isDrawingMode = false;
          // Add an event listener to the canvas for the mouse down event
          this.canvasFabric.on("mouse:down", (options) => {
            // Only add a new textbox if no textbox is currently selected
            if (this.tool === "textbox" && !(this.canvasFabric.getActiveObject() instanceof fabric.Textbox)) {
              var pointer = this.canvasFabric.getPointer(options.e);
              var textbox = new fabric.Textbox("Add Text...", {
                selectable: true,
                originX: "left",
                originY: "top",
                left: pointer.x, // Use the pointer x position
                top: pointer.y, // Use the pointer y position
                width: 200,
                fontSize: 20,
                fontFamily: "Arial",
                textAlign: "left",
                lineHeight: 1.2,
                fontWeight: "bold",
                fill: this.pickerColor,
                stroke: "white",
                strokeWidth: 0.3,
              });
              textbox.bringToFront();
              this.canvasFabric.add(textbox).setActiveObject(textbox);
              this.canvasFabric.renderAll();
            }
          });
          this.canvasFabric.on("text:editing:entered", function (e) {
            var textbox = e.target;
            document.addEventListener("keydown", function (e) {
              if (e.key === "Enter" || e.keyCode === 13) {
                textbox.exitEditing();
                this.canvasFabric.renderAll();
                document.removeEventListener("keydown", this);
              }
            });
          });
          break;
        }
        case "pen": {
          this.canvasFabric.isDrawingMode = true;
          const penBrush = new fabric.PencilBrush(this.canvasFabric);
          penBrush.width = this.penWidth;
          penBrush.color = this.pickerColor;
          this.canvasFabric.freeDrawingBrush = penBrush;
          break;
        }
        case "marker": {
          this.canvasFabric.isDrawingMode = true;
          const markerBrush = new fabric.PencilBrush(this.canvasFabric);
          markerBrush.width = 20;
          markerBrush.color = this.pickerColor + "54"; // .3 opacity
          this.canvasFabric.freeDrawingBrush = markerBrush;
          break;
        }
        case "eraser": {
          this.canvasFabric.isDrawingMode = true;
          this.canvasFabric.freeDrawingBrush = new fabric.EraserBrush(this.canvasFabric);
          this.canvasFabric.freeDrawingBrush.width = 20;
          break;
        }
        default:
        // nothing
      }
    },

    allowTextSelectionOnly() {
      this.canvasFabric.forEachObject(function (object) {
        object.selectable = object.type === "textbox";
      });
      this.canvasFabric.renderAll();
    },

    async submitComment(comment, snack = false) {
      // eslint-disable-next-line no-unused-vars
      const { index, showDetails, ...params } = comment;

      if (!comment.comment) {
        this.$snackbar.showMessage({
          content: this.$t("t_the_comment_is_empty"),
          color: "warning",
          timeout: "",
        });
        this.done();
        return;
      }

      let r;
      if (comment.id) {
        r = await IssueService.patchIssueComment(params);
      } else {
        r = await IssueService.addIssueComment(params);
      }

      this.comments[index].id = r.data?.issueCommentId;

      this.hideComment(index);

      if (snack) {
        this.$snackbar.showMessage({
          content: this.$t("t_comment_saved"),
          color: "success",
          timeout: "",
        });
      }
    },

    disableEraserMode() {
      this.canvasFabric.isDrawingMode = true;
      this.canvasFabric.freeDrawingBrush.color = this.pickerColor;
      this.canvasFabric.freeDrawingBrush.width = 5;
    },

    commentColor(severity) {
      if (severity < 2) return "#FFAB00";
      if (severity < 3) return "#E65100";
      if (severity < 4) return "#B71C1C";
      return "red";
    },

    toggleComment(index) {
      if (this.comments[index].showDetails && !this.isDragging) {
        if (!this.comments[index].id) {
          this.submitComment(this.comments[index], true);
        }
        this.hideComment(index);
      } else {
        this.$set(this.comments[index], "showDetails", true);
      }
    },

    hideComment(index) {
      if (!this.comments[index].comment) return;
      this.$set(this.comments[index], "showDetails", false);
    },

    triggerComment(event, comment) {
      if (this.isNotOwner(comment)) return;
      event.stopPropagation();
      this.currentComment = comment;
      this.offset.xCoord = this.currentComment.xCoord;
      this.offset.yCoord = this.currentComment.yCoord;
      document.addEventListener("mousemove", this.onCommentDrag);
      document.addEventListener("mouseup", this.endCommentDrag);
      document.addEventListener("touchmove", this.onCommentDrag);
      document.addEventListener("touchend", this.endCommentDrag);
    },

    onCommentDrag(event) {
      if (!this.currentComment) return;
      this.isDragging = true;
      const { xPercent, yPercent } = this.getCoords(event);
      if (xPercent < 1 || xPercent > 99 || yPercent < 1 || yPercent > 99) return;

      const comment = document.getElementById("editComment_" + this.currentComment.index);

      if (xPercent > 70) {
        comment?.classList.toggle("comment-left", true);
      } else {
        comment?.classList.toggle("comment-left", false);
      }

      this.currentComment.xCoord = xPercent;
      this.currentComment.yCoord = yPercent;
    },

    endCommentDrag() {
      document.removeEventListener("mousemove", this.onCommentDrag);
      document.removeEventListener("mouseup", this.endCommentDrag);
      document.removeEventListener("touchmove", this.onCommentDrag);
      document.removeEventListener("touchend", this.endCommentDrag);
      const idx = this.currentComment.index;
      const comment = this.comments[idx];
      this.$set(this.comments[idx], "xCoord", this.currentComment.xCoord);
      this.$set(this.comments[idx], "yCoord", this.currentComment.yCoord);
      this.currentComment = null;
      this.offset = { x: 0, y: 0 };
      setTimeout(() => {
        this.isDragging = false;
        if (comment.id) {
          IssueService.patchIssueComment(this.comments[idx]);
        }
      }, 100);
    },

    addComment(event) {
      if (this.mode !== "comment") return;
      const { xPercent, yPercent } = this.getCoords(event);
      this.comments.push({
        issueId: this.issueId,
        index: this.comments.length,
        xCoord: xPercent,
        yCoord: yPercent,
        comment: "",
        severity: 2,
        showDetails: true,
      });
    },

    getCoords(event) {
      const canvas = this.$refs.comments;
      const rect = canvas.getBoundingClientRect();
      let x, y;

      if (event.touches && event.touches.length > 0) {
        x = event.touches[0].clientX - rect.left;
        y = event.touches[0].clientY - rect.top;
      } else {
        x = event.clientX - rect.left;
        y = event.clientY - rect.top;
      }
      return {
        xPercent: (x / rect.width) * 100,
        yPercent: (y / rect.height) * 100,
      };
    },

    async removeCommentConfirm(index, event) {
      if (await this.$refs.confirm.open("Confirm", this.$t("c_confirm.t_confirm_delete"))) {
        this.removeComment(index, event);
      }
    },

    async removeComment(index, event) {
      if (event) event.stopPropagation();
      const comment = this.comments[index];
      if (comment?.id) {
        await IssueService.deleteIssueComment(this.comments[index]?.id);
      }
      this.comments.splice(index, 1);
    },

    async loadImage() {
      const canvasWrap = this.$refs.canvasWrap;
      this.canvasWidth = canvasWrap.offsetWidth;
      this.imageSrc = `data:image/jpeg;base64,${this.imagePhotoProp?.base64String}`;
      const img = new Image();
      img.src = this.imageSrc;

      img.onload = () => {
        let aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / aspectRatio;
        this.initFabric();
      };

      //set resize listener - -   set canvas dimensions
      const resizeListener = () => {
        const canvasWrap = this.$refs.canvasWrap;
        this.canvasWidth = canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;

        this.resizeCanvas();
      };

      window.addEventListener("resize", resizeListener);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });

      this.imageUpload();
    },

    async addIssue(params) {
      if (!params) {
        if (!this.issue) return;
        params = {
          description: this.issue.description,
          status: this.issue.status,
          imageAssetId: this.issue.assetId,
          imageAssetPublicId: this.issue.publicId,
        };
      }
      const r = await IssueService.addIssue(params);
      this.issueId = r.data?.issueId;
      this.$snackbar.showMessage({
        content: "Log-it " + this.$t("t_photo_uploaded"),
        color: "success",
        timeout: "",
      });
    },

    async imageUpload(file = null) {
      // Handle existing imagePhotoProp case
      if (this.imagePhotoProp) {
        this.pending();
        const base64String = this.imagePhotoProp.base64String;
        const base64Data = new Buffer.from(base64String, "base64");
        const photoExt = "jpg";
        const photoName = `log-it-photo_${new Date().getTime()}.${photoExt}`;

        const payload = {
          name: photoName,
          description: "",
          originalFileName: photoName,
          mimeType: "image/jpeg",
          cacheable: true,
          permitCompany: true,
          includeThumbnail: true,
        };

        try {
          const response = await AssetService.uploadAssetBegin(payload);

          // Process thumbnail
          const thumbnailBase64 = await this.processImage(base64String, {
            maxDimension: 600,
            quality: 0.7,
          });

          // Convert thumbnail to Buffer
          const thumbnailBuffer = new Buffer.from(thumbnailBase64, "base64");

          // Upload original image
          await fetch(response.data.uploadUrl, {
            method: "PUT",
            headers: {
              "Content-Type": this.imagePhotoProp.format,
              "Content-Encoding": "base64",
            },
            body: base64Data,
          });

          // Upload thumbnail
          await fetch(response.data.thumbnailUploadUrl, {
            method: "PUT",
            headers: {
              "Content-Type": this.imagePhotoProp.format,
              "Content-Encoding": "base64",
            },
            body: thumbnailBuffer,
          });

          await AssetService.uploadAssetComplete({
            assetId: response.data.assetId,
            hasThumbnail: true,
          });

          this.issue.status = "n";
          this.issue.imageAssetId = response.data.assetId;
          this.issue.imageAssetPublicId = response.data.assetGuid;

          const params = {
            description: "",
            status: "n",
            imageAssetId: response.data.assetId,
            imageAssetPublicId: response.data.assetGuid,
          };
          await this.addIssue(params);

          return response.data.assetId;
        } catch (error) {
          console.error("Error uploading image:", error);
          throw error;
        } finally {
          this.done();
        }
      }

      // Handle new file upload case
      try {
        const formData = new FormData();
        formData.append("file", file);

        // Get the upload URLs
        const response = await AssetService.UploadAssetBegin({
          issueId: this.issueId,
          fileName: file.name,
          fileSize: file.size,
          contentType: file.type,
          includeThumbnail: true,
        });

        // Get base64 string from file for thumbnail processing
        const reader = new FileReader();
        const base64Promise = new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result.split(",")[1]);
          reader.readAsDataURL(file);
        });
        const base64String = await base64Promise;

        // Process thumbnail image
        const thumbnailBase64 = await this.processImage(base64String, {
          maxDimension: 600,
          quality: 0.7,
        });

        // Convert thumbnail base64 back to Blob
        const thumbnailBlob = await fetch(`data:${file.type};base64,${thumbnailBase64}`).then((res) => res.blob());

        // Upload original image
        await fetch(response.data.uploadUrl, {
          method: "PUT",
          body: file,
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type,
          },
        });

        // Upload thumbnail image
        await fetch(response.data.thumbnailUploadUrl, {
          method: "PUT",
          body: thumbnailBlob,
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type,
          },
        });

        // Complete the upload
        await AssetService.UploadAssetComplete({
          issueId: this.issueId,
          assetId: response.data.assetId,
          hasThumbnail: true,
        });

        return response.data.assetId;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },

    saveMarkup() {
      this.saveCanvasAsJSON();
      this.drawSavePending = false;
      this.$snackbar.showMessage({
        content: "Log-it " + this.$t("t_saved"),
        color: "success",
        timeout: "",
      });
    },

    async saveCanvasAsJSON() {
      this.pending();
      const existingMarkupAssetId = this.issue?.markupAssetId;
      this.canvasFabric.width = this.canvasWidth;
      this.canvasFabric.height = this.canvasHeight;
      const jsonCanvas = this.canvasFabric.toJSON(["width", "height"]);
      const jsonCanvasString = JSON.stringify(jsonCanvas);
      const photoExt = "json";
      const photoName = `log-it-markup_${this.issueId}.${photoExt}`;
      const payload = {
        name: photoName,
        description: "",
        originalFileName: photoName,
        mimeType: "application/json",
        cacheable: true,
        permitCompany: true,
      };

      try {
        const a = await AssetService.uploadAssetBegin(payload);
        const uploadUrl = a.data.uploadUrl;
        const assetGuid = a.data.assetGuid;
        const markupAssetId = a.data.assetId;

        let params = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: jsonCanvasString,
        };

        const r = await fetch(uploadUrl, params);

        if (r.ok) {
          const publicId = assetGuid;
          this.issue.markupAssetId = markupAssetId;

          await AssetService.uploadAssetComplete({ assetId: markupAssetId });

          const params = {
            id: this.issueId,
            markupAssetId: markupAssetId,
            markupAssetPublicId: publicId,
          };

          const r = await IssueService.patchIssue(params);

          if (existingMarkupAssetId && !r.hasErrors) {
            await AssetService.deleteAsset(existingMarkupAssetId, true);
          }
        } else {
          await AssetService.deleteAsset(a.data.assetId, true);
        }
      } catch (error) {
        console.log("An error occurred during the asset upload process:", error);
      } finally {
        this.done();
      }
      setTimeout(() => {
        this.done();
      }, 8000);
    },

    submitIssue() {
      if (this.drawSavePending) {
        this.saveCanvasAsJSON();
      }

      const unsavedComments = this.comments.filter((obj) => obj.id === undefined);
      unsavedComments.forEach((obj) => this.submitComment(obj, false));

      this.$router.push("/issues");
      this.$snackbar.showMessage({
        content: "Log-it " + this.$t("t_saved"),
        color: "success",
        timeout: "",
      });
    },

    async updateIssue() {
      if (this.issueId && this.issue.description.length > 0) {
        const params = { id: this.issueId, description: this.issue.description, status: this.issue.status };
        await IssueService.patchIssue(params);
        this.$snackbar.showMessage({
          content: "Log-it " + this.$t("t_saved"),
          color: "success",
          timeout: "",
        });
      }
    },

    async deleteIssueConfirm() {
      if (await this.$refs.confirm.open("Confirm", this.$t("c_confirm.t_confirm_delete"))) {
        this.deleteIssue();
      }
    },

    async deleteIssue() {
      const params = { id: this.issueId, status: "d" };
      await IssueService.patchIssue(params);
      this.$snackbar.showMessage({
        content: "Log-it " + this.$t("t_deleted"),
        color: "success",
        timeout: "",
      });
      this.$router.push("/issues");
    },

    ///////// Load Issue ///////////////

    async loadIssue() {
      this.pending();
      this.issue = this.issueProp;
      this.issueId = this.issue.id;
      await this.getPhotoBase64();
      if (this.issue.markupAssetId) {
        await this.getCanvasJSON();
      }
      this.getIssueComments();
      this.done();
    },

    async getIssueComments() {
      if (this.comments?.length) {
        this.comments = [];
      }
      let params = {
        issueId: this.issueId,
        "paging.orderbyfield": "id",
        "paging.ascending": true,
        "paging.skipnum": 0,
        "paging.takenum": 999999,
      };

      const r = await IssueService.getIssueComments(params);
      const comments = r.data;
      comments.forEach((c, idx) => {
        c.index = idx;
        this.comments.push(c);
      });
    },

    async getCanvasJSON() {
      let params = { assetId: this.issue.markupAssetId, token: this.issue.markupAssetPublicId };
      const r = await AssetService.downloadAsset(params);
      if (r?.data?.downloadUrl == null) return;
      const data = await fetch(r.data.downloadUrl);
      const json = await data.json();
      const originalWidth = json.width;
      const originalHeight = json.height;
      const newWidth = this.canvasFabric.width;
      const newHeight = this.canvasFabric.height;
      const scaleX = newWidth / originalWidth;
      const scaleY = newHeight / originalHeight;

      this.canvasFabric.loadFromJSON(json, () => {
        this.canvasFabric.forEachObject((obj) => {
          obj.scaleX *= scaleX;
          obj.scaleY *= scaleY;
          obj.left *= scaleX;
          obj.top *= scaleY;
          obj.setCoords();
        });
        this.canvasFabric.renderAll();
      });
    },

    async getPhotoBase64() {
      let params = { assetId: this.issue.imageAssetId, token: this.issue.imageAssetPublicId };
      const r = await AssetService.downloadAsset(params);
      if (r?.data?.downloadUrl == null) return;
      const d = await fetch(r.data.downloadUrl);
      const imageBlob = await d.blob();
      const base64 = await this.blobToBase64(imageBlob);
      const canvasWrap = this.$refs.canvasWrap;
      this.canvasWidth = canvasWrap.offsetWidth;
      const base64String = base64.split(",")[1];
      // // //reconstruct as jpeg:
      this.imageSrc = `data:image/jpeg;base64,${base64String}`;
      const img = new Image();
      img.src = this.imageSrc;

      img.onload = () => {
        this.canvasWidth = this.$refs.canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;
        this.originalWidth = this.canvasWidth;
        this.originalHeight = this.canvasHeight;
        this.initFabric();
      };

      const resizeListener = () => {
        const canvasWrap = this.$refs.canvasWrap;
        this.canvasWidth = canvasWrap.offsetWidth;
        this.aspectRatio = img.naturalWidth / img.naturalHeight;
        this.canvasHeight = this.canvasWidth / this.aspectRatio;

        this.resizeCanvas();
      };

      window.addEventListener("resize", resizeListener);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("resize", resizeListener);
      });
    },

    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = function (error) {
          reject(error);
        };
      });
    },

    isNotOwner(comment) {
      return comment.ownerId && comment.ownerId != this.user?.userId;
    },

    handleKeydown(e) {
      if ((e.key === "Delete" || e.keyCode === 46) && !e.target.tagName.match(/INPUT|TEXTAREA|SELECT/)) {
        let activeObject = this.canvasFabric.getActiveObject();
        // Check if the active object is a textbox and it's not in editing mode
        if (activeObject && activeObject.type === "textbox" && !activeObject.isEditing) {
          this.canvasFabric.remove(activeObject);
          this.canvasFabric.requestRenderAll();
        }
      }
    },

    resizeCanvas() {
      const newWidth = this.canvasWidth;
      const newHeight = this.canvasHeight;

      const scaleX = newWidth / this.originalWidth;
      const scaleY = newHeight / this.originalHeight;

      this.canvasFabric.getObjects().forEach((obj) => {
        const newLeft = obj.left * scaleX;
        const newTop = obj.top * scaleY;

        obj.set({
          left: newLeft,
          top: newTop,
          scaleX: obj.scaleX * scaleX,
          scaleY: obj.scaleY * scaleY,
        });

        obj.setCoords();
      });

      this.canvasFabric.setWidth(newWidth);
      this.canvasFabric.setHeight(newHeight);
      this.canvasFabric.renderAll();
      this.originalWidth = newWidth;
      this.originalHeight = newHeight;
    },

    initFabric() {
      const fabric = window.fabric;
      this.canvasFabric = new fabric.Canvas(this.$refs.canvasFabric, {
        isDrawingMode: this.mode == "draw",
        width: this.canvasWidth,
        height: this.canvasHeight,
      });

      const pencilBrush = new fabric.PencilBrush(this.canvasFabric);
      pencilBrush.width = this.penWidth;
      pencilBrush.color = this.pickerColor;
      this.canvasFabric.freeDrawingBrush = pencilBrush;

      this.canvasFabric.on("path:created", () => {
        //this.saveCanvasAsJSON();  //Save draw after draw events instead of save button:
        this.drawSavePending = true;
      });

      this.canvasFabric.on("object:modified", (options) => {
        if (options.target && options.target.type === "textbox") {
          // this.saveCanvasAsJSON();
          this.drawSavePending = true;
        }
      });

      window.addEventListener("keydown", this.handleKeydown);
    },
  },
  mounted() {
    if (this.issueProp) {
      this.loadIssue();
    } else if (this.imagePhotoProp) {
      this.loadImage();
    }
  },

  beforeDestroy() {
    this.setImage(null);
    document.removeEventListener("mousemove", this.onCommentDrag);
    document.removeEventListener("mouseup", this.endCommentDrag);
    document.removeEventListener("touchmove", this.onCommentDrag);
    document.removeEventListener("touchend", this.endCommentDrag);
  },
};
</script>

<style lang="scss">
.theme--dark .image-editor {
  background: #272727;
  .btn-editor,
  .btn-tool {
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.image-editor {
  background: #eee;
  padding-bottom: 15px;

  img.photo {
    position: absolute;
    top: 0;
    left: 0;
  }
  .canvas {
    width: 100%;
    height: auto;
  }
  .comment-nav,
  .tools {
    margin-top: -10px;
  }
  .btn-submit {
    border-radius: 3px !important;
    padding: 0px 10px !important;
    height: 33px !important;
  }
  .btn-editor {
    //border: 2px solid #dadada !important;
    border-radius: 3px !important;
    padding: 4px 6px !important;
    height: 34px !important;
    min-width: unset !important;
    &.active {
      background: rgba(255, 255, 255, 0.25);
    }
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .btn-tool {
    border-radius: 3px !important;
    //border: 2px solid #4f4f4f;
    padding: 4px 6px !important;
    height: 34px !important;
    min-width: 28px !important;
    //border: 1px solid rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.2);
    &.active {
      background: rgba(255, 255, 255, 0.25);
      //border: 2px solid #ccc;
    }
  }
  .canvas-wrap {
    position: relative;
    border-top: 2px solid #aaa;
    z-index: 4;
    margin: 0 15px;

    .canvas.fabric {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      z-index: 99;
    }
    .canvas-container {
      position: absolute !important;
      width: 100% !important;
      height: 100% !important;
      //padding-bottom: 100%;
      z-index: 1;
      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
    .upper-canvas {
      width: 100% !important;
      height: 100% !important;
    }

    .canvas {
      position: absolute;
      top: 0;
      left: 0;
      &.photo {
        z-index: 10;
      }
      &.draw {
        z-index: 20;
      }
    }
    &.draw {
      cursor: url("~@/assets/pencil.svg") 4 28, auto;
    }
    &.eraser {
      cursor: url("~@/assets/eraser.svg") 4 28, auto;
    }
    &.comment {
      cursor: url("~@/assets/comment-text.svg") 14 28, auto;
    }
    .comments-wrap {
      position: relative;
      .comment-wrap {
        margin: -25px 0px 0px -25px;
        position: absolute;
        z-index: 1;
        &.active {
          .trigger-show-comment .large-circle {
            stroke: rgba(250, 246, 177, 0.4);
            stroke-width: 12;
          }
        }
      }
      .comment-avatar {
        position: relative;
        left: 0px;
        top: -2px;
      }
      .trigger-show-comment {
        cursor: pointer;
        touch-action: none;
        width: 50px;
        height: 50px;
        &.show-move {
          cursor: move;
        }
        // .comment-avatar {
        //   z-index: 20;
        //   position: relative;
        //   border: 2px solid #fff !important;
        //   left: 7px;
        //   top: 7px;
        // }
        .edit-connect {
          position: relative;
          left: 23px;
          top: -15px;
          height: 30px;
          width: 5px;
          background: #fff;
          &.avatar {
            left: 23px;
            top: 0;
          }
        }
      }
      .edit-comment {
        cursor: default;
        position: relative;
        top: 15px;
        left: 0;
        background: #f4f4f4;
        padding: 8px 15px 15px;
        border: 5px solid #dedede;
        border-radius: 8px;
        width: 360px;
        .comment-read {
          background: #fff;
        }
        &.dark {
          background: #333;
          border: 5px solid #8c8b8b;
          .comment-read {
            background: #262626;
          }
        }
        &.comment-left {
          left: -280px;
          .edit-connect {
            left: 236px;
          }
        }
        .textarea textarea {
          line-height: 1.3;
          padding-bottom: 10px;
        }
        // .v-slider__track-background {
        //   background-color: transparent !important;
        // }
        .v-slider__track-fill {
          background-color: #5c5c5c !important;
        }
        .btn-close-comment {
          position: absolute;
          right: -15px;
          top: -15px;
          width: 25px;
          height: 25px;
          background: #dedede;
        }
      }
    }
  }
  .image-editor canvas {
    border: 1px solid #ccc;
  }
  @keyframes severity-level {
    from {
      transform: scale(0.5);
    }

    to {
      transform: scale(1);
    }
  }
}

@media (min-width: 768px) {
  .image-editor {
    .canvas-wrap {
      .comments-wrap {
        .edit-comment {
          position: relative;
          // top: -95px !important;
          // left: 60px !important;
          max-width: 420px !important;

          .edit-connect {
            position: relative;
            border-bottom: 5px solid #fbecec;
            left: -45px;
            top: 55px;
            width: 30px;
          }
          &.comment-left {
            left: -280px;
            .edit-connect {
              left: 236px;
            }
          }
        }
      }
    }
  }
}
</style>
